import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import styles from '../style';
import Swal from "sweetalert2";

const PopupConfirm = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const data = urlParams.get('data')?.split(',');

  const [dataDict, setInput] = useState({
    receiverType: '',
    receiverName: '',
  });
  const [imageData, setImageData] = useState(null);
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);
  const [stream, setStream] = useState(null);

  const currentUrl = window.location.href;
  const currentUrlObj = new URL(currentUrl);
  const currentParams = new URLSearchParams(currentUrlObj.search);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput((values) => ({ ...values, [name]: value }));
    if (name === "receiverType" && value === "รับเอง") {
      setInput(prevState => ({
        ...prevState,
        receiverName: currentParams.get('name')
      }));
    } else if (name === "receiverType" && value === "รับแทน") {
      setInput((values) => ({ ...values, receiverName: '' }));
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = mediaStream;
      setStream(mediaStream);
      setShowCamera(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถเข้าถึงกล้องได้',
        text: 'กรุณาตรวจสอบการอนุญาตการเข้าถึงกล้อง',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6',
      });
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    
    const photoData = canvas.toDataURL('image/png');
    setImageData(photoData);
    stopCamera();
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
    setShowCamera(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!imageData) {
      Swal.fire({
        icon: 'warning',
        title: 'กรุณาถ่ายภาพบัตรพนักงานคู่กับพัสดุ',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    try {
      const requestData = {
        dataDict,
        tracknumberList: data,
        signatureImage: imageData,
      };
      const requestDataUpdateStatus = {
        status: 'ST03',
        tracknumberList: data,
      };

      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/parcels/update-parcel-status`,
        requestDataUpdateStatus
      );
      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/parcels/update-parcel-success`, requestData);

      Swal.fire({
        icon: 'success',
        title: 'ลงชื่อรับพัสดุเสร็จสิ้น',
        text: 'กรุณาแจ้งเจ้าหน้าที่',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6',
      }).then((result) => {
        if (result.isConfirmed) {
          window.close();
        }
      });      
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Swal.fire({
          icon: 'warning',
          title: 'พัสดุถูกรับไปแล้ว',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6',
        });  
      } else {
        Swal.fire({
          icon: 'error',
          title: 'บันทึกข้อมูลไม่สำเร็จ',
          text: 'กรุณาลองใหม่อีกครั้ง',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6',
        }); 
        console.error("Error inserting data:", error);
      }
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={`${styles.adminPageBox} items-center justify-center flex bg-white mt-1`}>
          <select name="receiverType" value={dataDict.receiverType || ''} onChange={handleChange} className={`${styles.adminPageBtn} rounded-md font-sarabun`} required>
            <option value="" disabled>กรุณาเลือก</option>
            <option value="รับเอง" disabled={currentParams.get('sameName') === "false"}>รับเอง</option>
            <option value="รับแทน">รับแทน</option>
          </select>
          <input type="text" name="receiverName" value={dataDict.receiverName || ''} onChange={handleChange} placeholder="กรอกชื่อผู้รับพัสดุ" required className="font-sarabun input input-bordered ml-4" />
        </div>
        
        <div className={`${styles.flexCenter} flex-col`}>
          <br></br>
          {showCamera ? (
            <div className="relative w-full max-w-lg">
              <video 
                ref={videoRef} 
                autoPlay 
                playsInline 
                className="w-full border border-gray-300 rounded-md"
              />
              <div className="flex justify-center gap-4 mt-4">
                <button 
                  type="button" 
                  onClick={takePhoto} 
                  className={`${styles.adminPageBtn} rounded-md hover:bg-hoverBackground`}
                >
                  ถ่ายรูป
                </button>
                <button 
                  type="button" 
                  onClick={stopCamera} 
                  className={`${styles.adminPageBtn} rounded-md hover:bg-red-400`}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex gap-4 mb-4">
                <button 
                  type="button" 
                  onClick={() => fileInputRef.current.click()} 
                  className={`${styles.adminPageBtn} rounded-md hover:bg-hoverBackground`}
                >
                  อัพโหลดรูปภาพ
                </button>
                <button 
                  type="button" 
                  onClick={startCamera} 
                  className={`${styles.adminPageBtn} rounded-md hover:bg-hoverBackground`}
                  hidden
                >
                  ถ่ายรูป
                </button>
                <input 
                  type="file" 
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  accept="image/*"
                  className="hidden"
                />
              </div>
              
              {imageData && (
                <div className="mb-4">
                  <img 
                    src={imageData} 
                    alt="Uploaded" 
                    className="max-w-full max-h-[300px] border border-gray-300 rounded-md"
                  />
                </div>
              )}

              {!imageData && (
                <div className="border border-gray-300 rounded-md w-full max-w-lg h-[300px] flex items-center justify-center bg-gray-50">
                  <p className="text-gray-400">กรุณาถ่ายภาพบัตรพนักงานคู่กับพัสดุ</p>
                </div>
              )}
            </>
          )}
          
          <input 
            type="submit" 
            value={"บันทึกข้อมูล"} 
            className={`${styles.adminPageBtn} mt-5 cursor-pointer rounded-md hover:bg-hoverBackground w-full max-w-lg`} 
          />
        </div>
      </form>
    </div>
  );
};

export default PopupConfirm;