export const LANGUAGES = ["en", "th"];

export const STATUS = [
    { id: 'ST05', status: { th: "พัสดุเข้าสู่ระบบ", en: "Parcel cancle" }, style: 'text-yellow-500' },
    { id: 'ST01', status: { th: "กำลังขนส่งพัสดุ", en: "Parcel transporting" }, style: 'text-orange-500' },
    { id: 'ST02', status: { th: "ขนส่งพัสดุถึงปลายทาง", en: "Parcel transported finish" }, style: 'text-blue-600' },
    { id: 'ST03', status: { th: "รับพัสดุสำเร็จ", en: "Parcel receive success" }, style: 'text-green-600' },
    { id: 'ST04', status: { th: "พัสดุถูกตีกลับ", en: "Parcel cancel" }, style: 'text-red-500' },
    { id: 'ST06', status: { th: "ส่งคืนไปรษณีย์", en: "Return postage" }, style: 'text-gray-500' },
];

export const SHIPPED_BY = [
    { id: '' }
]

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

export const FILTER_ALL_DATA = 
    {
        checkBox: ['กำลังขนส่งพัสดุ', 'ขนส่งพัสดุถึงปลายทาง', 'รับพัสดุสำเร็จ', 'พัสดุถูกตีกลับ', 'ส่งคืนไปรษณีย์', 'พัสดุเข้าสู่ระบบ', 'ยังไม่ได้บันทึกค่าส่ง', 'รออนุมัติ', 'ไม่อนุมัติ', 'บันทึกค่าส่งแล้ว', 'สำนักงานใหญ่-นวนคร', 'อมตะซิตี้', 'โรงงานอมตะซิตี้', 'สำนักงานใหญ่-SKL', 'อื่นๆ', 'กล่อง', 'ซอง'],
        columnParcel: ['senderFullname', 'receiverFullname', 'tracknum'],
        endDate: today
    }
