import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import styles from "../../style";
import { shippedIDTranferIDToText, getPrice } from "../../services";
import axios from "axios";
import Swal from "sweetalert2";
import { GiCardboardBoxClosed } from "react-icons/gi";

const ParcelDetailTable = ({ 
  parcels, 
  handleChangeInput,
  selectedRows,
  handleRowSelect,
  handleMultipleDefaultWeight,
  handleSelectAll,
  selectAllChecked
}) => (
  <Box sx={{ overflow: "auto" }}>
    <Table
      size="small"
      aria-label="package details"
      sx={{
        "& .MuiTableCell-root": {
          fontFamily: "Sarabun",
        },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
              <input
                type="checkbox"
                checked={selectAllChecked}
                onChange={handleSelectAll}
              />
          </TableCell>
          <TableCell>ประเภทการจัดส่ง</TableCell>
          <TableCell>ประเภทพัสดุ</TableCell>
          <TableCell>ส่วน</TableCell>
          <TableCell>Cost Center</TableCell>
          <TableCell>GL</TableCell>
          <TableCell>Order</TableCell>
          <TableCell>เลขที่</TableCell>
          <TableCell>น้ำหนัก</TableCell>
          <TableCell>ค่าใช้จ่ายเพิ่มเติม</TableCell>
          <TableCell>ค่าตอบกลับ</TableCell>
          <TableCell>ค่าประกันสินค้าสูญหาย</TableCell>
          <TableCell>ค่าบริการ</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          backgroundColor: "#ffffff",
        }}
      >
        {parcels.map((parcel, index) => (
          <TableRow key={index}>
            <TableCell>
              <input
                type="checkbox"
                checked={selectedRows.includes(parcel.pcRunid)}
                onChange={() => handleRowSelect(parcel.pcRunid)}
              />
            </TableCell>
            <TableCell>{shippedIDTranferIDToText(parcel?.shippedBy)}</TableCell>
            <TableCell>{parcel?.parcelType}</TableCell>
            <TableCell>{parcel?.department}</TableCell>
            <TableCell>{parcel?.costcenter}</TableCell>
            <TableCell>{parcel?.gl}</TableCell>
            <TableCell>{parcel?.order}</TableCell>
            <TableCell>
              <input
                type="text"
                name="tracknum"
                value={parcel?.tracknum || ""}
                onChange={(e) =>
                  handleChangeInput(parcel.pcRunid, {
                    tracknum: e.target.value,
                  })
                }
                className="input input-bordered w-full"
              />
            </TableCell>
            <TableCell>
              <input
                type="number"
                name="weight"
                value={parcel?.weight || ""}
                onChange={(e) => {
                  if (parcel.tracknum) {
                    handleChangeInput(
                      parcel.pcRunid,
                      { weight: e.target.value },
                      parcel.shippedBy
                    );
                  } else {
                    Swal.fire({
                      icon: "warning",
                      title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                      confirmButtonText: "ตกลง",
                      confirmButtonColor: "#3085d6",
                    });
                  }
                }}
                className="input input-bordered w-16"
              />
            </TableCell>
            <TableCell>
              <input
                type="number"
                name="costplus"
                value={parcel?.costplus || 0}
                onChange={(e) => {
                  const value = e.target.value === "" ? 0 : Number(e.target.value);
                  if (parcel.tracknum) {
                    handleChangeInput(
                      parcel.pcRunid,
                      { costplus: value },
                      parcel.shippedBy
                    );
                  } else {
                    Swal.fire({
                      icon: "warning",
                      title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                      confirmButtonText: "ตกลง",
                      confirmButtonColor: "#3085d6",
                    });
                  }
                }}
                className="input input-bordered w-16"
              />
            </TableCell>
            <TableCell>
              <input
                type="number"
                name="costreply"
                value={parcel?.costreply || 0}
                onChange={(e) => {
                  const value = e.target.value === "" ? 0 : Number(e.target.value);
                  if (parcel.tracknum) {
                    handleChangeInput(
                      parcel.pcRunid,
                      { costreply: value },
                      parcel.shippedBy
                    );
                  } else {
                    Swal.fire({
                      icon: "warning",
                      title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                      confirmButtonText: "ตกลง",
                      confirmButtonColor: "#3085d6",
                    });
                  }
                }}
                className="input input-bordered w-16"
              />
            </TableCell>
            <TableCell>
              <input
                type="number"
                name="costgarantee"
                value={parcel?.costgarantee || 0}
                onChange={(e) => {
                  const value = e.target.value === "" ? 0 : Number(e.target.value);
                  if (parcel.tracknum) {
                    handleChangeInput(
                      parcel.pcRunid,
                      { costgarantee: value },
                      parcel.shippedBy
                    );
                  } else {
                    Swal.fire({
                      icon: "warning",
                      title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                      confirmButtonText: "ตกลง",
                      confirmButtonColor: "#3085d6",
                    });
                  }
                }}
                className="input input-bordered w-16"
              />
            </TableCell>
            <TableCell>{parcel?.fee}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#009B9F",
                  "&:hover": { backgroundColor: "#007B7F" },
                  borderRadius: "10px",
                  fontFamily: "Sarabun",
                }}
                onClick={() => {
                  if (parcel.tracknum) {
                    handleChangeInput(
                      parcel.pcRunid,
                      { weight: 10 },
                      parcel.shippedBy
                    );
                  } else {
                    Swal.fire({
                      icon: "warning",
                      title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                      confirmButtonText: "ตกลง",
                      confirmButtonColor: "#3085d6",
                    });
                  }
                }}
              >
                ราคาเริ่มต้น
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    {selectedRows.length > 0 && (
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#009B9F",
            "&:hover": { backgroundColor: "#007B7F" },
            borderRadius: "10px",
            fontFamily: "Sarabun",
          }}
          onClick={handleMultipleDefaultWeight}
        >
          ราคาเริ่มต้น (สำหรับรายการที่เลือก)
        </Button>
      </Box>
    )}
  </Box>
);

const ParcelGroup = ({ 
  senderName, 
  dateTime, 
  parcels,
  handleChangeInput,
  handleUpdateParcel,
  selectedRows,
  handleRowSelect,
  handleMultipleDefaultWeight
}) => {
  const [open, setOpen] = useState(false);
  const parcelCount = parcels.length;

  const allInGroupSelected = parcels.every(parcel => 
    selectedRows.includes(parcel.pcRunid)
  );

  const handleSelectAllInGroup = () => {
    if (allInGroupSelected) {
      const groupPcRunIds = parcels.map(parcel => parcel.pcRunid);
      handleRowSelect(groupPcRunIds, false);
    } else {
      const groupPcRunIds = parcels.map(parcel => parcel.pcRunid);
      handleRowSelect(groupPcRunIds, true);
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        overflow: "hidden",
        marginBottom: "16px",
      }}
      className="font-sarabun"
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#f2f2f2",
          padding: "12px",
          borderBottom: "1px solid #ddd",
          alignItems: "center",
        }}
      >
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </IconButton>
        <Box sx={{ flex: 2, display: "flex", justifyContent: "left" }}>
          ชื่อผู้ส่ง : {senderName}
        </Box>
        <Box sx={{ flex: 2, display: "flex", justifyContent: "left" }}>
          วันที่ - เวลา ที่ส่ง : {dateTime}
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
          จำนวนพัสดุ : {parcelCount} ชิ้น
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#009B9F",
              "&:hover": { backgroundColor: "#007B7F" },
              borderRadius: "10px",
              fontFamily: "Sarabun",
            }}
            onClick={() => handleUpdateParcel(parcels)}
          >
            บันทึกข้อมูล
          </Button>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ padding: 2 }}>
          <div className="flex justify-center items-center flex-col overflow-auto font-sarabun mb-4">
            <p>รายการพัสดุทั้งหมดที่ต้องบันทึกน้ำหนัก</p>
          </div>
          <ParcelDetailTable 
            parcels={parcels}
            handleChangeInput={handleChangeInput}
            selectedRows={selectedRows}
            handleRowSelect={handleRowSelect}
            handleMultipleDefaultWeight={handleMultipleDefaultWeight}
            handleSelectAll={handleSelectAllInGroup}
            selectAllChecked={allInGroupSelected}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

const AddWeighParcel = () => {
  const [parcelsCompany, setParcelsCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rates, setRate] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const calculateFee = (parcel, weight, shippedBy) => {
    if (weight === undefined || weight === "") return 0;

    const basePrice = Number(getPrice(weight, shippedBy, rates));
    const additionalCosts = [
      Number(parcel.costplus ?? 0),
      Number(parcel.costreply ?? 0),
      Number(parcel.costgarantee ?? 0),
    ].reduce((sum, cost) => sum + cost, 0);

    return basePrice + additionalCosts;
  };

  const handleRowSelect = (pcRunidOrIds, isSelecting = null) => {
    if (Array.isArray(pcRunidOrIds)) {
      if (isSelecting === true) {
        const idsToAdd = pcRunidOrIds.filter(id => !selectedRows.includes(id));
        setSelectedRows([...selectedRows, ...idsToAdd]);
      } else if (isSelecting === false) {
        setSelectedRows(selectedRows.filter(id => !pcRunidOrIds.includes(id)));
      }
    } else {
      const pcRunid = pcRunidOrIds;
      if (selectedRows.includes(pcRunid)) {
        setSelectedRows(selectedRows.filter(id => id !== pcRunid));
      } else {
        setSelectedRows([...selectedRows, pcRunid]);
      }
    }
  };

  const handleMultipleDefaultWeight = () => {
    const validParcels = selectedRows.every(
      selectedRow => parcelsCompany.find(
        parcel => parcel.pcRunid === selectedRow
      ).tracknum
    );
    
    if (validParcels) {
      const updatedParcels = parcelsCompany.map(parcel => {
        if (selectedRows.includes(parcel.pcRunid)) {
          const updatedParcel = { ...parcel, weight: 10 };
          updatedParcel.fee = calculateFee(
            updatedParcel,
            10,
            updatedParcel.shippedBy
          );
          return updatedParcel;
        }
        return parcel;
      });
      
      setParcelsCompany(updatedParcels);
    } else {
      Swal.fire({
        icon: "warning",
        title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
      });
    }
  };
  
  const groupedParcels = {};
  parcelsCompany.forEach(parcel => {
    const senderName = parcel.senderName || 'ไม่ระบุชื่อผู้ส่ง';
    const dateTime = parcel.date ? 
      new Date(parcel.date).toLocaleString('th-TH', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }) : 'ไม่ระบุเวลา';
    
    const key = `${senderName}_${dateTime}`;
    
    if (!groupedParcels[key]) {
      groupedParcels[key] = {
        senderName: senderName,
        dateTime: dateTime,
        parcels: []
      };
    }
    groupedParcels[key].parcels.push(parcel);
  });

  useEffect(() => {
    const getParcelsCompany = async () => {
      try {
        const ParcelsCompanyList = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/parcels/get-parcel-admin-company`
        );
        setParcelsCompany(ParcelsCompanyList.data);
      } catch (error) {
        console.error("Error get parcels company", error);
      } finally {
        setLoading(false);
      }
    };
    getParcelsCompany();
  }, []);

  useEffect(() => {
    const getRate = async () => {
      try {
        const ratesList = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/postal-rates/get-rate`
        );
        setRate(ratesList.data);
      } catch (error) {
        console.error("Error get postal service rates", error);
      }
    };
    getRate();
  }, []);

  const handleChangeInput = (
    pcRunid,
    newValue,
    shippedId
  ) => {
    const calculateFee = (parcel, weight, shippedBy) => {
      if (weight === undefined || weight === "") return 0;

      const basePrice = Number(getPrice(weight, shippedBy, rates));
      const additionalCosts = [
        Number(parcel.costplus ?? 0),
        Number(parcel.costreply ?? 0),
        Number(parcel.costgarantee ?? 0),
      ].reduce((sum, cost) => sum + cost, 0);

      return basePrice + additionalCosts;
    };

    const updateParcel = (parcel) => {
      let updatedParcel = { ...parcel };

      Object.keys(newValue).forEach((key) => {
        if (newValue[key] !== undefined) {
          updatedParcel[key] = newValue[key];
        }
      });

      // ไม่คำนวณ fee ถ้ามีการอัปเดท tracknum
      if (!newValue.hasOwnProperty("tracknum")) {
        updatedParcel.fee = calculateFee(
          updatedParcel,
          updatedParcel.weight,
          updatedParcel.shippedBy
        );
      }

      return updatedParcel;
    };

    const updatedParcels = parcelsCompany.map((parcel) => {
      if (parcel.pcRunid === pcRunid) {
        return updateParcel(parcel);
      }
      return parcel;
    });

    setParcelsCompany(updatedParcels);
  };

  const handleUpdateParcel = async (parcelsToUpdate = parcelsCompany) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/parcels/update-parcel-weight`,
        { parcelsCompany: parcelsToUpdate }
      );
      Swal.fire({
        icon: "success",
        title: "บันทึกข้อมูลสำเร็จ!",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Swal.fire({
          icon: "error",
          title: "บันทึกข้อมูลไม่สำเร็จ",
          text: "เลขพัสดุถูกใช้งานแล้ว",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#3085d6",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "บันทึกข้อมูลไม่สำเร็จ",
          text: "กรุณาลองใหม่อีกครั้ง",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#3085d6",
        });
        console.error("Error inserting data:", error);
      }
    }
  };

  return (
    <div>
      <p className={`${styles.pageHeading}`}>บันทึกน้ำหนักพัสดุ</p>
      <div className={`${styles.adminPageBox} mt-12`}>
        {loading ? (
          <div role="status" className="flex items-center justify-center h-screen">
            <svg
              aria-hidden="true"
              className="inline w-12 h-12 text-gray-200 animate-spin dark:text-borderLine fill-secondary"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          Object.keys(groupedParcels).length > 0 ? (
            <Box sx={{ padding: 2 }}>
              {Object.values(groupedParcels).map((group, index) => (
                <ParcelGroup
                key={index}
                senderName={group.senderName}
                dateTime={group.dateTime}
                parcels={group.parcels}
                handleChangeInput={handleChangeInput}
                handleUpdateParcel={handleUpdateParcel}
                selectedRows={selectedRows}
                handleRowSelect={handleRowSelect}
                handleMultipleDefaultWeight={handleMultipleDefaultWeight}
              />
              ))}
            </Box>
          ) : (
            <div className="flex justify-center items-center flex-col overflow-auto">
              <GiCardboardBoxClosed style={{ fontSize: "300px" }} />
              <p style={{ fontSize: "30px" }} className="font-sarabun">
                ไม่มีพัสดุที่ต้องบันทึกน้ำหนัก
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AddWeighParcel;